const channelToggleButtons = document.querySelectorAll('.audio__radio-program-toggler') as NodeListOf<HTMLElement>;

channelToggleButtons.forEach(btn => {
  btn.addEventListener('click', e => {
    const button = e.target as HTMLElement;
    const targetChannel = button.dataset.channel;

    const radioProgramWrappers = document.querySelectorAll('.audio__radio-program-toggles') as NodeListOf<HTMLElement>;
    radioProgramWrappers.forEach(element => element.classList.add('d-none'));

    document.querySelectorAll('.audio__radio-program-toggles') as NodeListOf<HTMLElement>;
    radioProgramWrappers.forEach(element => element.classList.add('d-none'));

    const targetRadioProgramWrapper = document.querySelector(`#${targetChannel}`) as HTMLElement;
    targetRadioProgramWrapper.classList.remove('d-none');

    channelToggleButtons.forEach(element => {
      element.classList.toggle('btn-outline-primary');
      element.classList.toggle('btn-primary');
    });
  });
});
